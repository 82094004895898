<template>
    <div class="title">
        <div style="display: inline-block; height: 20px;">
            <div @click.stop="liShow" class="title_default active_color">{{selectCon}}</div>
            <i class="iconfont icon-xuanzeqizhankai_o expand" @click.stop="liShow"></i>
        </div>
        <ul v-show="isShow" style="z-index: 2000;">
            <li :class="selectCon == item.lable?'active_color':'public_text'" v-for="item in list" :key="item.value" @click.stop="changeLanguages(item)">
                {{item.lable}}
            </li>
        </ul>
    </div>
</template>
<script>
    export default {
        props: {
            list: {
                type: Array,
                default: () => ([])
            }
        },
        data() {
            return {
                checkedData: [], //选中的数据
                isShow: false, //下拉列表是否显示
                selectCon: "简体中文", //选中的内容
            };
        },
        mounted() {
            if (sessionStorage.getItem("localeDate")) {
                this.selectCon = JSON.parse(sessionStorage.getItem("localeDate")).selectCon;
            }
            let that = this;
            //点击页面空白处隐藏下拉列表
            document.addEventListener("click", function () {
                if (that.isShow == true) {
                    that.isShow == false;
                }
            });
        },
        methods: {
            //控制下拉列表的显示隐藏
            liShow() {
                this.isShow = !this.isShow;
                console.log("展开")
            },
            // 切换语种
            changeLanguages(item) {
                console.log(item.value)
                this.selectCon = item.lable;
                this.$i18n.locale = item.value;
                this.isShow = false;
                let data = {
                    selectCon: item.lable,
                    locale: item.value
                }
                sessionStorage.setItem("localeDate", JSON.stringify(data))
                this.$emit('changeLocale');
            },
        }
        // watch: {
        //     liData: {
        //         handler(newVal, oldVal) {
        //             //选中数据
        //             this.checkedData = newVal.filter(function (item) {
        //                 return item.Check == true;
        //             });
        //             //在页面打印出的数据
        //             this.selectCon = ""; //点击的当前项的展示
        //             for (var i = 0; i < this.checkedData.length; i++) {
        //                 this.selectCon += this.checkedData[i].Name + "  ";
        //             }
        //             // 给父组件传值
        //             this.$emit("change", this.selectCon);
        //         },
        //         deep: true
        //     }
        // }
    }
</script>
<style scoped>
    .expand {
        font-size: 14px;
    }

    .title {
        display: inline-block;
        position: relative;
        /* padding: 0 30px; */
    }

    .title_default {
        display: inline-block;
    }

    .public_text {
        font-size: 14px;
        color: #000000;
    }

    .active_color {
        font-size: 14px;
        color: rgba(57, 152, 211, 1);
    }


    input[type="text"] {
        width: 300px;
        height: 40px;
        padding: 0 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-radius: 3px;
        -moz-border-radius: 3px;
        /* Firefox */
        -webkit-border-radius: 3px;
        /* Safari 和 Chrome */
        border-radius: 3px;
        /* Opera 10.5+, 以及使用了IE-CSS3的IE浏览器 */
    }

    .i {
        position: absolute;
        width: 30px;
        height: 40px;
        line-height: 38px;
        right: -12px;
        top: 1px;
        text-align: center;
        cursor: pointer;
    }

    ul {
        position: absolute;
        right: 0px;
        top: 46px;
        border-radius: 3px;
        -moz-border-radius: 3px;
        /* Firefox */
        -webkit-border-radius: 3px;
        /* Safari 和 Chrome */
        border-radius: 3px;
        /* Opera 10.5+, 以及使用了IE-CSS3的IE浏览器 */
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
        /* Firefox */
        -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
        /* Safari 和 Chrome */
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
        /* Opera 10.5+, 以及使用了IE-CSS3的IE浏览器 */
        width: max-content;
        /* border: 1px solid #ccc; */
        /* padding: 5px 2px; */
        text-align: left;
        background-color: #ffffff;
    }

    li {
        line-height: 30px;
        padding: 0 8px;
    }
</style>