<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  export default {
    data() {
      return {

      }
    },
    created() {
      // 禁用选择
      document.onselectstart = new Function("event.returnValue=false");
      if (sessionStorage.getItem("localeDate")) {
        console.log("if locale")
        console.log(JSON.parse(sessionStorage.getItem("localeDate")).defaultLocale)
        this.$i18n.locale = JSON.parse(sessionStorage.getItem("localeDate")).defaultLocale;
      } else {
        this.$i18n.locale = 'zh';
        console.log("else locale")
      }
    },
    mounted() {
    },
    watch: {
      $route(to, from) {
        if (to != from.path) {
          window.scrollTo(0, 0)
        }
      }
    }
  }
</script>

<style>
  * {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  #app {
    min-height: 100vh;
  }

  .icon,
  .iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
  }

  .van-checkbox__icon van-checkbox__icon--square {
    border-color: #999 !important;
  }

  .van-checkbox__icon--checked .van-icon {
    color: #999 !important;
    /* font-size: 15px !important; */
    background-color: transparent !important;
    border-color: #999 !important;
    /* background-color: #1989fa; */
    /* border-color: #1989fa; */
  }

  img {
    -webkit-touch-callout: none;
  }

  body {
    -webkit-overflow-scrolling: touch;
  }

  html {
    -webkit-text-size-adjust: none;
  }

  input::placeholder {
    line-height: normal;
  }
</style>