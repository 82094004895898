<template>
    <transition name="confirm-fade">
        <div v-if="isShowConfirm" class="my-confirm" @click.stop="clickCancelFun('clickCancel')">
            <div class="confirm-content-wrap" @click.stop>
                <h3 class="my-confirm-title" v-show="titleText != ''">{{ titleText }}</h3>
                <p class="my-confirm-content">{{ content }}</p>
                <div class="my-operation">
                    <div v-if="type==='confirm' || type === 'timer'" class="my-cancel-btn"
                        @click="clickCancelFun('clickCancel')">
                        <p class="my-btn-text my-border-right">{{ cancelText }}</p>
                    </div>
                    <!-- <div class="confirm-btn" v-if="type!=='timer'" @click="clickFun('clickConfirm')">
                        <p class="my-btn-text">{{ confirmText }}</p>
                    </div> -->
                    <!-- v-if="type==='timer'"  -->
                    <div v-if="type === 'timer'" :class="timerNumber===0?'confirm-btn':'confirm-btn'"
                        @click="clickFun('clickConfirm')">
                        <p
                            :class="timerNumber===0?'my-btn-text':'my-btn-text  confirm-btn-bak confirm-btn-active-color'">
                            {{ confirmText }}
                            <span v-show="timerNumber!==0"
                                class="my-btn-text confirm-btn-active-color">({{timerNumber}})</span>
                        </p>
                    </div>
                    <div v-if="type === 'confirm'" class="confirm-btn" @click="clickFun('clickConfirm')">
                        <p class="my-btn-text">
                            {{ confirmText }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<!-- <script type="text/ecmascript-6"> -->
<script>
    export default {
        data() {
            return {
                isShowConfirm: false, // 用于控制整个窗口的显示/隐藏
                titleText: '操作提示', // 提示框标题
                content: 'Say Something ...', // 提示框的内容
                cancelText: '取消', // 取消按钮的文字
                confirmText: '确认', // 确认按钮的文字
                type: 'confirm', // 表明弹框的类型：confirm - 确认弹窗（有取消按钮）；alert - 通知弹框（没有取消按钮）
                outerData: null, // 用于记录外部传进来的数据，也可以给外部监听userBehavior，事件的函数提供判断到底是哪个事件触发的
                // 定时器倒计时    
                timerNumber: 5,
                // 定时器
                timer: null
            }
        },
        methods: {
            show(content, config) {
                this.content = content || 'Say Something ...'

                if (Object.prototype.toString.call(config) === '[object Object]') {
                    // 确保用户传递的是一个对象
                    this.titleText = config.titleText || ''
                    this.cancelText = config.cancelText || '取消'
                    this.confirmText = config.confirmText || '确认'
                    this.type = config.type || 'confirm'
                    this.outerData = config.data || null
                }

                if (config.type == 'timer') {
                    this.timerNumber = 5
                    this.timer = setInterval(() => {
                        this.timerNumber--
                        if (this.timerNumber == 0) {
                            clearInterval(this.timer)
                            this.timer = null
                        }
                    }, 1000)
                }

                this.isShowConfirm = true
            },
            hidden() {
                this.isShowConfirm = false
                this.titleText = '操作提示'
                this.cancelText = '取消'
                this.confirmText = '确认'
                this.type = 'confirm'
                this.outerData = null
            },
            clickCancelFun(type) {
                clearInterval(this.timer)
                this.timer = null
                this.timerNumber = 5
                this.$emit('userBehavior', type, this.outerData)
                this.hidden()
            },
            clickFun(type) {
                if (this.type === 'timer') {
                    if (this.timerNumber === 0) {
                        console.log(this.timerNumber === 0);
                        this.$emit('userBehavior', type, this.outerData)
                        this.hidden()
                    }
                    return
                }
                this.$emit('userBehavior', type, this.outerData)
                this.hidden()
            }
        }
    }
</script>

<style scoped>
    .my-confirm {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 998;
        /* 这里防止当用户长按屏幕，出现的黑色背景色块，以及 iPhone 横平时字体的缩放问题 */
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    /* 进入和出去的动画 */
    .confirm-fade-enter-active {
        animation: opacity 0.3s;
    }

    .confirm-fade-enter-active .confirm-content-wrap {
        /* width: 248px; */
        animation: scale 0.3s;
    }

    .confirm-fade-leave-active {
        animation: outOpacity 0.2s;
    }

    /* 包裹层容器样式 */
    .confirm-content-wrap {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        width: 248px;
        margin: -25% auto 0 auto;
        background-color: rgba(255, 255, 255, 1);
        border-radius: 4px;
        z-index: 999;
        user-select: none;
    }

    /* 顶部标题部分 */
    .my-confirm-title {
        padding-top: 20px;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        color: #333;
    }

    /* 中间内容部分 */
    .my-confirm-content {
        width: 248px;
        margin: 0 auto;
        /* padding: 0 15px; */
        padding: 20px 0;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        line-height: 1.5;
    }

    /* 底部按钮样式 */
    .my-operation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* border-top: 1px solid #eee; */
    }

    .my-operation .my-cancel-btn,
    .confirm-btn {
        /* flex: 1; */
        width: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 8px;
    }

    .confirm-btn-bak {
        background-color: rgba(204, 204, 204, 1);
    }

    .confirm-btn-active-color {
        color: rgba(153, 153, 153, 1) !important;
    }

    /* .my-operation .confirm-btn {
        color: #ffb000;
    } */

    .my-operation .my-btn-text {
        width: 88px;
        height: 24px;
        margin: auto;
        font-size: 14px;
        color: #000000;
        font-weight: 600;
        text-align: center;
        line-height: 24px;
        /* margin: 8px 0; */
        /* padding-bottom: 6px; */
    }

    /* 其他修饰样式 */
    .my-border-right {
        border-right: 1px solid rgba(51, 51, 51, 1);
    }

    /* 进来的动画 */
    @keyframes opacity {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes scale {
        0% {
            transform: scale(0);
        }

        60% {
            transform: scale(1.1);
        }

        100% {
            transform: scale(1);
        }
    }

    /* 出去的动画 */
    @keyframes outOpacity {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }
</style>