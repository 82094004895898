<template>
  <div class="home" @scroll="handleScroll">
    <dialog-view ref="myDialog" @userBehavior="changeData"></dialog-view>
    <!-- <div class="languages" v-for="(item, index) in gradeList" :key="index">
      <div class="languages_lable">{{item.lable}}</div>
    </div> -->
    <div class="home_box_header">
      <img v-if="logoUrl" class="home_only" :src="logoUrl" alt="">
      <div class="my_selete">
        <!-- <customSelect @changeLocale="changeLocale" :list="gradeList"></customSelect> -->
        <change-lang @changeLocale="changeLocale"></change-lang>
      </div>
      <!-- 刷新 -->
      <!-- <div v-if="user_info.length != 0" class="login_out login_out_pos" @click="userCliLoginOut">
        <img src="@/assets/img/shuaxin.png" alt="">
        <p>{{$t('shuaxin')}}</p>
      </div> -->
      <!-- 未登录文案 -->
      <p class="user_title" v-if="user_info.length === 0">
        {{$t('loginTitleText')}}
      </p>
      <!-- 非标准 -->
      <p class="user_title" v-if="false">
        {{$t('nonstandardText')}}
      </p>
      <!--  登录并且没有自己的模型 -->
      <p class="user_title" v-if="user_info.length != 0 && !userList.userModelDefault && !userList.shootUserModel">
        {{$t('goToShootText')}}
      </p>
      <!-- 登录, 模型制作中 -->
      <p class="user_title1" v-if="user_info.length != 0 && userList.userModelDefault && userList.shootUserModel">
        {{$t('inProductionText')}}
      </p>
      <!-- 登录, 模型制作中 排队名次文案 -->
      <p class="user_title" v-if="user_info.length != 0 && userList.userModelDefault && userList.shootUserModel">
        {{shootNumberText}}
      </p>
      <!-- <p class="user_title">您当前排在第 5 位，预计明天生成您的数字分身</p> -->
      <div v-if="user_info.length != 0" class="login_out1_pos">
        <div @click="goArFrame" class="login_out">
          <img src="@/assets/img/AR.png" alt="">
          <p>{{$t('AR')}}</p>
        </div>
        <div @click="cameraHistory" class="login_out">
          <img src="@/assets/img/record.png" alt="">
          <p>{{$t('shootingRecord')}}</p>
        </div>
        <div class="login_out border_none" @click="userCliLoginOut">
          <img src="@/assets/img/login_out1.png" alt="">
          <p>{{$t('loginOut')}}</p>
        </div>
      </div>
      <div class="user_model" id="pos">
        <div class="aaprocess" v-show="modelState"
          :style="{width: progressWidth + 'px',marginLeft: -(progressWidth / 2) + 'px'}">
          <d-progress :stroke-width="4" :percentage="progressNum" color="#17b86a" :show-text="false"></d-progress>
        </div>
      </div>
      <div class="but_flex">
        <div v-for="(item, index) in motionList" :key="index"
          :class="btnState==index?'model_but_active hidden':'model_but hidden'" v-show="isAnimationBut"
          @click="changeMotion(item,index)">
          {{item.motionName}}
        </div>
      </div>
    </div>
    <div v-show="videoChildList">
      <div class="user_video">
        <div class="user_video_title">
          <span></span>
          <!-- 登录并且有模型 -->
          <div class="user_video_text"
            v-if="user_info.length != 0 && userList.userModelDefault == true && userList.shootUserModel == false">
            {{$t("myVideo")}}
          </div>
          <div class="user_video_text" v-else>
            {{$t('templateVideo')}}
          </div>
          <span></span>
        </div>
      </div>
      <div class="video_box" v-if="videoChildList.length > 0">
        <div class="video_box_child" v-for="(item, index) in videoList.dataList" :key="index">
          <div class="video_child" :style="{backgroundImage: `url(${item.userVideoImg})`}"
            @click="goVideoDetails(item)">
            <i class="iconfont icon-24gf-playCircle video_play"></i>
          </div>
          <span class="video_text">{{item.userVideoName}}</span>
        </div>
      </div>
    </div>
    <div>
    </div>
    <div class="user_login" @click="toLogin" v-if="user_info.length == 0">{{$t("login")}}</div>
    <!-- <div class="user_login1" @click="goArFrame">AR</div> -->
    <!-- <div class="userModelStateText" v-if="user_info.length != 0">{{userList.userModelDefaultText}}</div> -->
  </div>
</template>
<script>
  /*
    userModelDefault 是否有自己的模型    shootUserModel 是否正在制作
    userModelDefault == true && shootUserModel == true 正在制作
    userModelDefault == true && shootUserModel == false 制作完成有模型
    userModelDefault == false && shootUserModel == false 没有自己的模型
  */
  /*
     1、 未登录情况下 || 登录情况下没有模型 请求模板视频列表
     2、 登录有模型 || 登录制作中 请求视频列表
  */
  import DProgress from "@/components/progress.vue";
  import * as THREE from 'three';
  import { sRGBEncoding } from 'three';
  //此处加载gltf模型，故使用GLFTLoader
  import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
  //其他模型加载器
  import { OBJLoader, MTLLoader } from 'three-obj-mtl-loader';
  import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
  import {
    FBXLoader
  } from 'three/examples/jsm/loaders/FBXLoader.js';
  import customSelect from "@/components/selete.vue";
  import changeLang from "@/components/changeLang.vue";
  import { Toast } from "vant";
  import "vant/lib/index.css"
  import throttle from "@/utils/common.js"
  import dialogView from "@/components/dialogView.vue"
  var element;
  export default {
    name: 'Home',
    components: {
      DProgress,
      customSelect,
      changeLang,
      dialogView
    },
    data() {
      return {
        // modelUrl: "https://ustyle-dev.oss-cn-hangzhou.aliyuncs.com/model/1635743108409.glb",
        modelUrl: "",
        group: null,
        camera: null,
        renderer: null,
        scene: null,
        controls: null,
        btnState: 0,
        motionUrlOne: "https://ustyle-dev.oss-cn-hangzhou.aliyuncs.com/model/1682658882321.fbx",
        motionUrlTwo: "https://ustyle-dev.oss-cn-hangzhou.aliyuncs.com/model/1682658883713.fbx",
        testUrl: "https://ustyle-dev.oss-cn-hangzhou.aliyuncs.com/model/1635743108409.glb",
        // motionUrl: "https://ustyle-dev.oss-cn-hangzhou.aliyuncs.com/model/1685689898156.fbx",
        animationBonesList: [],
        glbNum: 0,
        userModelScan: null,
        mixer: null,
        flock: null,
        clock: null,
        progressWidth: 150,
        progressNum: 0,
        modelState: false,
        gradeList: [
          {
            lable: "简体中文",
            value: "zh"
          },
          {
            lable: "English",
            value: "en"
          },
          // {
          //   lable: "한국어",
          //   value: "ko"
          // },
          // {
          //   lable: "日本語",
          //   value: "ja"
          // }
        ],
        logoUrl: "",
        userList: [],
        videoList: [],
        videoChildList: [],
        motion: 0,
        // 当前多少页
        page: 1,
        // 每页多少条
        pageLimit: 10,
        user_info: [],
        // local: this.$i18n.locale.loginOut,
        motionList: [],
        bulletState: false,
        isButState: false,
        shootNumberText: "",
        arAnimation: []
      }
    },
    async created() {
      if (this.$route.query.token && this.$route.query.userName && this.$route.query.user) {
        let data = {
          accessToken: this.$route.query.token,
          userEmail: this.decodeURIBase64(this.$route.query.userName),
          type: 1,
          appAlias: "USTYLE",
          userId: this.decodeURIBase64(this.$route.query.user)
        }
        console.log("满足条件登录");
        console.log(data);
        localStorage.setItem("h5_user_info", JSON.stringify(data))
      }
      //base64解密
      // let userName = decodeURIComponent(this.$route.query.user)
      // console.log(this.decodeURIBase64(this.$route.query.userName));
      // console.log(this.decodeURIBase64(this.$route.query.user));

      if (localStorage.getItem("h5_user_info")) {
        this.user_info = JSON.parse(localStorage.getItem('h5_user_info'))
      }
      // await this.getLogo()
      await this.getUserModel()
      await this.getUserVideoList()
      this.getArFbx()
    },
    mounted() {
      this.$on('change', () => {
        console.log("change接收事件")
        // this.getUserModel()
        this.getUserVideoList()
        if (localStorage.getItem('h5_user_info')
          && this.userList.userModelDefault
          && this.userList.shootUserModel) {
          this.getUserShootNumber()
        }
        // this.getUserModel()
      })
      element = document.getElementById('pos')
      this.init()
    },
    computed: {
      isAnimationBut() {
        // console.log(this.userList)
        // console.log(this.userList.userModelDefault && !this.userList.shootUserModel ? true : !this.userList.userModelDefault && !this.userList.shootUserModel ? true : false);
        return this.userList.userModelDefault && !this.userList.shootUserModel ? true : !this.userList.userModelDefault && !this.userList.shootUserModel ? true : false
      }
    },
    methods: {
      /*
 userModelDefault 是否有自己的模型    shootUserModel 是否正在制作
 userModelDefault == true && shootUserModel == true 正在制作
 userModelDefault == true && shootUserModel == false 制作完成有模型
  userModelDefault == false && shootUserModel == false 没有自己的模型
 */

      decodeURIBase64(str) {
        return window.atob(decodeURIComponent(str))
      },
      async getArFbx() {
        let that = this
        let loaderFbx = new FBXLoader()
        await loaderFbx.load('https://ustyle-dev.oss-cn-hangzhou.aliyuncs.com/model/1685689898156.fbx', function (child) {
          if (child.animations.length > 0) {
            // console.log()
            that.arAnimation = child.animations[0]
            var animationsJson = that.arAnimation.map(function (clip) {
              return clip.toJSON();
            });
            var blob = new Blob(
              [JSON.stringify(animationsJson)],
              { type: 'application/json' }
            );
            var downloadLink = document.createElement('a');
            console.log(downloadLink);
            downloadLink.href = URL.createObjectURL(blob);
            downloadLink.download = 'animations.json';
            downloadLink.click();
            console.log(that.arAnimation);
            console.log(JSON.stringify(that.arAnimation));
            sessionStorage.setItem("arAnimation", JSON.stringify(that.arAnimation.toJson()))
          }
        }, function (xhr) {
          // console.log((xhr.loaded / xhr.total * 100) + '% loaded')
          if ((xhr.loaded / xhr.total * 100) == 100) {
          }
        },
          function (error) {
            console.log('An error happened')
          })
      },
      cameraHistory() {
        this.$router.push({
          path: "/model_list"
        })
      },
      goArFrame() {
        sessionStorage.setItem("ar_state", '1')
        let url = encodeURIComponent(this.userList.modelUrl)
        this.$router.push({
          path: `/arFrame?modelUrl=${url}`
        })
      },
      // 确定弹框返回的值
      sendState(state) {
        console.log(state)
        this.bulletState = false
        if (state) {
          this.loginOut()
        } else {

        }
      },
      // 接收子组件发送事件
      changeLocale() {
        let data;
        if (localStorage.getItem('h5_user_info')) {
          data = {
            language: this.$i18n.locale,
            // email: JSON.parse(localStorage.getItem('h5_user_info')).userEmail ? JSON.parse(localStorage.getItem('h5_user_info')).userEmail : '',
            // phone: JSON.parse(localStorage.getItem('h5_user_info')).userPhone ? JSON.parse(localStorage.getItem('h5_user_info')).userPhone : ''
          }
        } else {
          data = {
            language: this.$i18n.locale,
          }
        }
        // modelUrl
        this.$http.post('getIndexModelData', data).then(res => {
          console.log(res)
          if (res.resultCode == 200) {
            this.userList = res.data
            this.motionList = res.data.buttonList;
          } else {
            Toast.fail(this.$t('getVideoListError'))
          }
        })
        this.getUserVideoList()
        // this.getUserModel()
      },
      getTemplateDataVideo() {

      },
      // 去视频详情
      goVideoDetails(item) {
        if (item.userVideoId) {
          this.$router.push({
            path: `/video_details?videoId=${item.userVideoId}&userVideoImgHeight=${item.userVideoImgHeight}&userVideoImgWidth=${item.userVideoImgWidth}&userModelDefault=${this.userList.userModelDefault}`
          })
        } else {
          this.$router.push({
            path: `/video_details?videoId=${item.videoTemplateId}&userVideoImgHeight=${item.userVideoImgHeight}&userVideoImgWidth=${item.userVideoImgWidth}`
          })
        }

      },
      // 获取视频列表
      async getUserVideoList() {
        console.log(this.userList)
        let data, res
        if (localStorage.getItem('h5_user_info')) {
          data = {
            language: this.$i18n.locale,
            pid: this.userList.pid,
            currentPage: this.page,
            pageLimit: this.pageLimit
          }
        } else {
          data = {
            language: this.$i18n.locale,
            currentPage: this.page,
            pageLimit: this.pageLimit
          }
        }
        // 如果登录 && 有模型，并且制作完成
        if (localStorage.getItem('h5_user_info') && this.userList.userModelDefault && !this.userList.shootUserModel) {
          res = await this.$http.post('getVideoList', data)
        } else {
          res = await this.$http.post('getVideoTemplateList', data)
        }
        console.log(res)
        if (res.resultCode == 200) {
          this.videoList = res.data;
          this.videoChildList = res.data.dataList;
        }
      },
      // 获取模板视频列表
      getTemplateVideoList() {
        let data
        if (localStorage.getItem('h5_user_info')) {
          data = {
            language: this.$i18n.locale,
            pid: this.userList.pid,
            currentPage: this.page,
            pageLimit: this.pageLimit
          }
        } else {
          data = {
            language: this.$i18n.locale,
            currentPage: this.page,
            pageLimit: this.pageLimit
          }
        }
        this.$http.post('getVideoTemplateList', data).then(res => {
          console.log(res)
          if (res.resultCode == 200) {
            this.videoList = res.data;
            this.videoChildList = res.data.dataList;
          }
        })
      },
      // 滚动 视频列表
      async handleScroll(e) {
        const { scrollTop, clientHeight, scrollHeight } = e.target
        let res;
        // console.log(scrollTop, clientHeight, scrollHeight)
        // console.log(scrollTop + clientHeight)
        // console.log(scrollHeight)
        if (scrollHeight - (scrollTop + clientHeight) < 10) {
          if (!localStorage.getItem("h5_user_info") || localStorage.getItem("h5_user_info") && !this.userList.userModelDefault) {
            return
          }
          this.page++
          let data = {
            language: this.$i18n.locale,
            // email: JSON.parse(localStorage.getItem('h5_user_info')).userEmail ? JSON.parse(localStorage.getItem('h5_user_info')).userEmail : '',
            // phone: JSON.parse(localStorage.getItem('h5_user_info')).userPhone ? JSON.parse(localStorage.getItem('h5_user_info')).userPhone : '',
            pid: this.userList.pid,
            currentPage: this.page,
            pageLimit: this.pageLimit
          }
          if (localStorage.getItem("h5_user_info") && this.userList.userModelDefault) {
            res = await this.$http.post('getVideoList', data)
          }
          console.log(res)
          if (res.resultCode == 200) {
            if (res.data.dataList.length <= 0) {
              console.log('已经到底了')
              // Toast('已经到底了')
            }
            res.data.dataList.forEach(item => {
              this.videoList.push(item)
            });
          }

        }
      },
      async getUserShootNumber() {
        let data = {
          language: this.$i18n.locale,
          pid: this.userList.pid
        }
        const res = await this.$http.post("getShootNumber", data)
        if (res.success) {
          this.shootNumberText = res.data.text
        }
        console.log(res);
      },
      // 获取首页模型各项数据
      async getUserModel() {
        let data;
        if (localStorage.getItem('h5_user_info')) {
          data = {
            language: this.$i18n.locale,
            // email: JSON.parse(localStorage.getItem('h5_user_info')).userEmail ? JSON.parse(localStorage.getItem('h5_user_info')).userEmail : '',
            // phone: JSON.parse(localStorage.getItem('h5_user_info')).userPhone ? JSON.parse(localStorage.getItem('h5_user_info')).userPhone : ''
          }
        } else {
          data = {
            language: this.$i18n.locale,
          }
        }
        const res = await this.$http.post('getIndexModelData', data)
        console.log(res)
        if (res.resultCode == 200) {
          this.userList = res.data;
          this.modelUrl = res.data.modelUrl;
          this.motionList = res.data.buttonList;
          console.log(this.userList.userModelDefault, this.userList.shootUserModel)
          if (res.data.modelUrl) {
            this.modelState = true
            setTimeout(() => {
              this.showModel()
            }, 500)
          } else {
            this.modelState = false
          }
          // 正在制作
          if (this.user_info.length != 0 && this.userList.userModelDefault && this.userList.shootUserModel) {
            await this.getUserShootNumber()
          }

          // 如果登录 发送事件 获取视频列表
          // if (localStorage.getItem("h5_user_info") && this.videoList == []) {
          //   this.$emit("change")
          // }
        } else {
          Toast.fail(this.$t('getVideoListError'))
        }
      },
      // 获取logo
      async getLogo() {
        const res = await this.$http.post('getLogo')
        if (res.success) {
          this.logoUrl = res.data;
        }
      },
      // 退出登录弹框
      changeData(type, data) {
        if (type === 'clickConfirm') {
          // console.log("clickConfirm")
          if (data.type === 'loginOut') { // 删除模型
            // console.log("loginOut")
            this.loginOut()
          }
        }
      },

      // 点击出现退出登录弹框
      userCliLoginOut() {
        this.$refs.myDialog.show(this.$t('loginOutTitle'), {
          type: 'confirm',
          confirmText: this.$t('yes'),
          cancelText: this.$t('no'),
          titleText: '',
          data: { type: 'loginOut', operate: 'exchange' }
        })
      },
      loginOut() {
        let data = {
          language: this.$i18n.locale,
          mail: JSON.parse(localStorage.getItem('h5_user_info')).userEmail ? JSON.parse(localStorage.getItem('h5_user_info')).userEmail : '',
          username: JSON.parse(localStorage.getItem('h5_user_info')).userPhone ? JSON.parse(localStorage.getItem('h5_user_info')).userPhone : ''
        }
        this.$http.post('loginOut', data).then(res => {
          // console.log(res)
          if (res.resultCode == 200) {
            Toast(this.$t('loginOutSuccess'))
            localStorage.clear()
            if (this.$route.query.token && this.$route.query.userName && this.$route.query.user) {
              this.$router.replace({ path: this.$route.path, query: {} });
            }
            this.user_info = []
            this.videoList = []
            this.deleteModle()
            this.getUserModel()
            this.getUserVideoList()
            // console.log('path: ' + this.$route.path);
          } else {
            Toast(this.$t('loginOutError'))
            localStorage.clear()
            this.user_info = []
            this.videoList = []
            this.deleteModle()
            this.getUserModel()
            this.getUserVideoList()
          }
        })
      },
      toLogin() {
        this.$router.push({
          path: "/login"
        })
      },
      updateMrLeft() {
        // return {marginLeft: }
      },
      changeMotion: throttle(async function (item, idx) {
        if (this.btnState == idx) return
        else this.btnState = idx
        this.animationBonesList = []
        if (item.motionUrl) {
          this.modelState = true
          var loaderFbx = new FBXLoader();
          var that = this
          await loaderFbx.load(item.motionUrl, function (child) {
            // console.log(child.animations)
            if (child.animations.length > 0) {
              var ani = 0
              // for (var i = 0; i < child.animations.length; i++) {
              //   that.animationBonesList.push(child.animations[i])
              // }
              // console.log(child.animations[ani])
              that.animationBonesList.push(child.animations[ani]);
              // console.log(that.animationBonesList)
              that.mixer = new THREE.AnimationMixer(that.userModelScan)
              that.mixer.clipAction(that.animationBonesList[0]).play()
            }
          }, function (xhr) {
            // console.log((xhr.loaded / xhr.total * 100) + '% loaded')
            if ((xhr.loaded / xhr.total * 100) == 100) {
              that.modelState = false
            }
          },
            function (error) {
              console.log('An error happened')
              that.modelState = false
            })
        } else {
          await this.deleteModle()
          await this.deleteModle()
          await setTimeout(() => {
            this.showModel()
          }, 500)
        }
      }, 300),
      deleteModle() {
        // console.log("删除模型")
        this.group.traverse(function (item) {
          if (item instanceof THREE.Mesh) {
            item.geometry.dispose() // 删除几何体
            item.material.dispose() // 删除材质
            item = null
          }
        });
        if (this.group.children.length > 0) {
          for (var i = 0; i < this.group.children.length; i++) {
            var currObj = this.group.children[i]
            // 判断类型
            if (currObj instanceof THREE.Scene) {
              var children = currObj.children;
              this.group.remove(this.group.children[i])
              for (var s = 0; s < children.length; s++) {
                this.deleteGroup(children[s])
              }
            } else {
              // console.log("删除模型22222")
              this.group.remove(this.group.children[i])
              this.deleteGroup(currObj)
            }
          }
        }
      },
      deleteGroup(group) {
        if (!group) return
        group.traverse(function (item) {
          if (item.type === 'SkinnedMesh') {
            item.geometry.dispose() // 删除几何体
            item.material.dispose() // 删除材质
          }
        });
        this.scene.remove(group)
        this.renderer.renderLists.dispose()
        this.renderer.dispose()
        this.renderer.content = null
        THREE.Cache.clear()
      },
      init() {

        var width = element.offsetWidth //窗口宽度
        var height = element.offsetHeight //窗口高度
        var k = width / height //窗口宽高比
        var s = 170 //三维场景显示范围控制系数，系数越大，显示的范围越大
        this.scene = new THREE.Scene()
        this.group = new THREE.Group()
        this.camera = new THREE.PerspectiveCamera(45, width / height, 1, 1000)
        this.clock = new THREE.Clock()
        this.camera.position.set(0, 0, 60) //设置相机位置
        this.renderer = new THREE.WebGLRenderer({
          //增加下面两个属性，可以抗锯齿
          antialias: true,
          alpha: true,
          //将渲染保存到缓冲区，否则获取的图片会是空的
          preserveDrawingBuffer: true,//是否保留缓冲区直到手动清除或覆盖。默认值为false
          precision: 'mediump'  //着色器的精度。可以是"highp", "mediump" 或 "lowp". 默认为"highp"，如果设备支持的话。
        })
        this.renderer.setPixelRatio(window.devicePixelRatio)
        this.renderer.setSize(width, height)
        // 定义gammaOutput和gammaFactor
        this.renderer.gammaOutput = true
        this.renderer.gammaFactor = 2.0

        // this.renderer.setClearColor(0x000000,1.0)
        // 点光源
        // let point = new THREE.PointLight(0xffffff, 0.6)
        // point.position.set(-50, 75, -50); // 点光源位置
        // this.scene.add(point); // 点光源添加到场景中
        document.body.appendChild(this.renderer.domElement)
        this.controls = new OrbitControls(this.camera, this.renderer.domElement)
        this.controls.enableDamping = true // 使动画循环使用时阻尼或自转 意思是否有惯性
        this.controls.enableZoom = true //允许缩放
        //设置相机移动距离
        this.controls.minDistance = 36
        this.controls.maxDistance = 100
        //上下翻转的最大角度
        this.controls.maxPolarAngle = 2.0
        //上下翻转的最小角度
        this.controls.minPolarAngle = 0.3
        this.controls.enableRotate = true
        let ambientLight = new THREE.AmbientLight(0xffffff, 0)
        let directionalLight = new THREE.DirectionalLight(0x666666, 0)
        // 设置光源位置
        directionalLight.position.set(20, 75, 20)
        this.scene.add(ambientLight)
        this.scene.add(this.group)
        document.getElementById('pos').appendChild(this.renderer.domElement)
        this.renderer.render(this.scene, this.camera)
        this.render()
      },
      showModel() {
        console.log(this.modelUrl)
        let loader = new GLTFLoader()
        this.flock = new THREE.AnimationObjectGroup
        loader.load(this.modelUrl, (gltf) => {
          gltf.scene.traverse((child) => {
            if (child.isMesh) {
              child.material.emissive = child.material.color
              child.material.emissiveIntensity = 1.6
              child.material.emissiveMap = child.material.map
              child.material.transparent = true
              child.isLineSegments = true
              child.material.wireframe = false
            }
          });
          // 创建相机锁定点
          var ss = new THREE.Vector3(gltf.scene.position.x, gltf.scene.position.y, gltf.scene.position.z)
          this.camera.lookAt(ss) //设置相机方向(指向的场景对象)
          gltf.scene.position.y = -20
          gltf.scene.scale.set(20, 20, 20)
          this.group.add(gltf.scene)
          this.flock.add(this.group)
          this.userModelScan = gltf.scene
          this.playaction()
        }, (xhr) => {
          // console.log((xhr.loaded / xhr.total * 100) + '% loaded');
          this.progressNum = xhr.loaded / xhr.total * 100;
          if (parseInt(xhr.loaded / xhr.total * 100) == 100) {
            console.log("进度为100")
            console.log("modelState:" + this.modelState)
            this.modelState = false
          }
        }, (err) => {
          console.log(err)
          this.modelState = false
        })
      },
      getModelAnimatedBones(url) {
        var loaderFbx = new FBXLoader()
        var that = this
        console.log(url)
        loaderFbx.load(url, function (child) {
          // console.log(child.animations)
          if (child.animations.length > 0) {
            var ani = 0
            // for (var i = 0; i < child.animations.length; i++) {
            //   that.animationBonesList.push(child.animations[i])
            // }
            console.log(child.animations[ani])
            that.animationBonesList.push(child.animations[ani])
            console.log(that.animationBonesList)
          }
        }, function (xhr) {
          console.log((xhr.loaded / xhr.total * 100) + '% loaded')
        },
          function (error) {
            console.log('An error happened')
          }
        )
      },
      playaction() {
        // console.log(this.userModelScan)
        // console.log(this.animationBonesList)
        // console.log(this.animationBonesList.length)
        if (this.userModelScan && this.animationBonesList.length != 0) {
          // console.log("我有动画，执行了")
          // console.log(this.animationBonesList)
          // console.log(this.userModelScan)
          this.mixer = new THREE.AnimationMixer(this.userModelScan)
          for (var i = 0; i < this.animationBonesList.length; i++) {
            console.log(this.animationBonesList[i])
            this.mixer.clipAction(this.animationBonesList[i]).play()
          }
          console.log(this.mixer)
        } else {
          // console.log("我没有动画，未执行")
        }
      },
      render() {
        this.controls.update()
        this.renderer.render(this.scene, this.camera)
        requestAnimationFrame(this.render)
        // console.log(mixer)
        if (this.mixer != null) {
          // 更新混合器相关的时间
          this.mixer.update(this.clock.getDelta())
        }
      }
    }
  }
</script>
<style scoped>
  .home {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    background-image: url('../assets/img/home_back.png');
    background-repeat: no-repeat;
    /* 让背景图基于容器大小伸缩 */
    background-size: cover;
    background-attachment: fixed;
    background-position-y: 100%;
  }

  .home_box_header {
    position: relative;
  }

  .home_only {
    width: 48px;
    height: 48px;
    position: absolute;
    left: 20px;
    top: 20px;
  }

  .my_selete {
    /* display: flex;
    flex-wrap: nowrap;
    justify-content: end; */
    position: absolute;
    right: 16px;
    top: 10px;
    z-index: 2000;
  }

  .video_box {
    margin: 20px auto;
    width: 320px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .video_box_child {
    width: max-content;
    height: max-content;
    margin: 0 0 15px 0;
  }

  .video_child {
    width: 140px;
    height: 140px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    line-height: 140px;
    border-radius: 6px;
  }

  .video_text {
    width: 140px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #ffffff;
    font-size: 13px;
    /* 对象作为弹性伸缩盒子模型显示 */
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .video_play {
    color: #ffffff;
    font-size: 30px;
  }

  .login_out {
    /* width: 56px; */
    width: 100%;
    height: 66px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid rgba(204, 204, 204, 1);
    /* justify-content: space-around; */
    /* background-color: #ffffff; */
  }

  .border_none {
    border: none;
  }

  .login_out p {
    width: 100%;
  }

  .login_out img {
    width: 20px;
  }

  .login_out_pos {
    position: absolute;
    top: 69px;
    right: 10px;
  }

  .user_title {
    position: absolute;
    font-size: 16px;
    color: #ffffff;
    width: 70%;
    left: 50%;
    text-align: center;
    top: 32px;
    margin-left: -35%;
  }

  .user_title1 {
    position: absolute;
    font-size: 16px;
    color: #ffffff;
    width: 100%;
    text-align: center;
    top: 50%;
  }

  .login_out1_pos {
    position: absolute;
    top: 69px;
    right: 16px;
    z-index: 999;
    padding: 9px 0;
    background-color: rgba(247, 247, 247, 0.3);
    backdrop-filter: blur(1px);
    width: 56px;
    height: max-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 4px;
  }

  .login_out p {
    font-size: 12px;
    color: #ffffff;
    /* height: 30px;
    line-height: 30px; */
    text-align: center;
  }

  .login_out img {
    width: 20px;
    height: 20px;
    margin: 12px 0 8px 0;
  }

  .languages {
    font-size: 13px;
  }

  .spa {
    font-size: 14px;
  }

  .user_model {
    width: 100%;
    height: 460px;
    position: relative;
  }

  .but_flex {
    width: 326px;
    height: 30px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
  }

  .aaprocess {
    position: absolute;
    left: 50%;
    top: 30%;
  }

  .model_but {
    border: 1px solid #ffffff;
    width: 98px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    font-size: 13px;
    border-radius: 2px;
    color: #ffffff;
  }

  .model_but_active {
    width: 100px;
    height: 30px;
    background: rgba(57, 152, 211, 1);
    text-align: center;
    line-height: 30px;
    font-size: 13px;
    border-radius: 2px;
    color: #ffffff;
  }

  .hidden {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .user_video {
    width: 100%;
    padding-top: 26px;
  }

  .user_video_title {
    margin: 0 auto;
    width: max-content;
    color: rgba(20, 31, 93, 1);
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    font-weight: 600;
    /* height: 40px; */
  }

  .user_video_title span {
    width: 80px;
    height: 1px;
    background-color: #ffffff;
    display: inline-block;
  }

  .user_video_text {
    padding: 0 4px;
    color: #ffffff;
    font-size: 15px;
  }

  .user_login {
    width: 88px;
    height: 88px;
    border-radius: 50%;
    background: linear-gradient(216.83deg, rgba(34, 133, 214, 1) 0%, rgba(34, 97, 186, 1) 100%);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    line-height: 88px;
    color: #ffffff;
    font-size: 20px;
    letter-spacing: 5px;
    position: fixed;
    bottom: 30px;
    left: 50%;
    margin-left: -44px;
    /* position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -44px; */
  }

  .user_login1 {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: linear-gradient(216.83deg, rgba(34, 133, 214, 1) 0%, rgba(34, 97, 186, 1) 100%);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    text-align: center;
    line-height: 44px;
    color: #ffffff;
    font-size: 18px;
    letter-spacing: 3px;
    position: fixed;
    bottom: 130px;
    left: 50%;
    margin-left: -22px;
  }

  .userModelStateText {
    width: 252px;
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    margin: 20px auto;
  }
</style>