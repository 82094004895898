import Vue from 'vue'
import confirm from './index.vue'
let confirmConstructor = Vue.extend(confirm)
let myConfirm = function (text) {
    return new Promise((resolve, reject) => {
        let confirmDom = new confirmConstructor({
            el: document.createElement('div')
        })
        document.body.appendChild(confirmDom.$el) // new一个对象，然后插入body里面
        confirmDom.text = text // 为了使confirm的扩展性更强，这个采用对象的方式传入，所有的字段都可以根据需求自定义
        confirmDom.handleOk = function () {
            resolve()
            confirmDom.isShow = false
        }
        confirmDom.handleClose = function () {
            reject()
            confirmDom.isShow = false
        }
    })
}
export default myConfirm