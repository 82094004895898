<template>
    <div class="lang" @click="changeLanguage">
        <p class="i18n">{{local}}</p>
        <img class="change_lang_img" src="@/assets/img/change_lang.png" alt="">
    </div>
</template>
<script>
    export default {
        data() {
            return {
                local: "EN"
            }
        },
        created() {
            if (sessionStorage.getItem("localeDate")) {
                this.local = JSON.parse(sessionStorage.getItem("localeDate")).locale;
            }
        },
        methods: {
            changeLanguage() {
                if (this.$i18n.locale == 'zh') {
                    this.$i18n.locale = 'en'
                    this.local = 'ZH'
                    let data = {
                        locale: this.local,
                        defaultLocale: this.$i18n.locale
                    }
                    sessionStorage.setItem("localeDate", JSON.stringify(data))
                    this.$emit('changeLocale');
                } else {
                    this.$i18n.locale = 'zh'
                    this.local = 'EN'
                    let data = {
                        locale: this.local,
                        defaultLocale: this.$i18n.locale
                    }
                    sessionStorage.setItem("localeDate", JSON.stringify(data))
                    this.$emit('changeLocale');
                }
            }
        },
    }
</script>
<style scoped>
    .lang {
        width: max-content;
        display: flex;
        flex-wrap: nowrap;
        justify-content: end;
        align-items: center;
    }

    .i18n {
        font-size: 14px;
        color: #ffffff;
        margin-right: 5px;
    }

    .change_lang_img {
        width: 14px;
    }
</style>