const zh = {
  login: '登录',
  digital: "数字分身",
  changePhoneText: "请输入手机号码",
  changeCodeText: "请输入短信验证码",
  registerOrLogin: '注册/登录',
  mailboxLogin: '邮箱登录',
  mailboxAccount: "请输入邮箱账号",
  mailboxCode: '请输入邮箱验证码',
  readAndAgree: '已阅读并同意',
  UserAgreement: '《用户协议》',
  and: "及",
  privacyTerms: "《隐私条款》",
  phoneLogin: "手机号登录",
  sRetrieve: "后重新获取",
  Korean: "韩语",
  Japanese: "日语",
  myVideo: "我的数字分身视频",
  loginOut: "退出登录",
  getVerificationCode: "获取验证码",
  dimension: "未注册用户验证通过后将自动注册",
  showText: "请先勾选协议",
  shuaxin: "刷新",
  deleteText: '删除成功',
  loginTitleText: "请先登录",
  goToShootText: "请找工作人员拍摄",
  nonstandardText: "非标准模型",
  inProductionText: '制作中',
  shootingRecord: "拍摄记录",
  AR: "AR植入",
  templateVideo: "数字分身视频",
  yes: "确定",
  no: "取消",
  loginOutTitle: "确定退出登录数字分身嘛？",
  modelListTitle: "勾选设为首页默认展示的数字分身形象",
  logOffTitle: "注销后此账号的所有信息及数据将被清空且无法找回，您仍要注销吗？",
  deleteModelText: "确定删除分身吗？",
  isChangeModel: "是否更改默认模型？",
  isLogOff: '注销账号',
  delete: "删除",
  captureTime: "拍摄时间",
  captureLocation: "拍摄地点",
  loginOutSuccess: '退出成功',
  loginOutError: '退出失败',
  errNumber: "号码错误",
  versionErr: '验证码错误',
  emailError: "邮箱错误",
  emailFormat: '邮箱格式不正确',
  numNoNull: "手机号不能为空",
  numberFormat: '手机号格式不正确',
  sendSuccess: '发送成功',
  sendError: '发送失败',
  missingParameters: '缺少参数',
  getVideoListError: '获取视频列表失败',
  logOffSuccess: "注销成功",
  logOffError: '注销失败',
  deleteSuccess: '删除成功',
  deleteError: '删除失败',
  getShootError: '获取拍摄列表失败',
  shareText: '已复制分享链接，可以进行粘贴分享',
  downLoad: '下载',
  share: '分享',
  downLoadSuccess: "视频开始下载，稍后请到文件夹/相册内查看",
  language: {
    zh: '中文',
    en: 'English',
    ko: '한국어',
    ja: '日本語',
  },
}
export default zh;
