import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "@/views/Home.vue"
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/video_details',
    name: 'video_details',
    component: () => import('../components/video_details.vue')
  },
  {
    path: '/user_policy',
    name: 'user_policy',
    component: () => import('../components/user_policy.vue')
  },
  {
    path: '/arFrame',
    name: 'arFrame',
    component: () => import('../views/arFrame.vue')
  },
  {
    path: '/model_list',
    name: 'model_list',
    component: () => import('../views/model_list.vue')
  },
  {
    path: '/user_video',
    name: 'user_video',
    component: () => import('../components/user_video.vue')
  },
]

const router = new VueRouter({
  mode: "hash",
  routes
})

export default router
