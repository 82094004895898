import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "amfe-flexible"
// babel-polyfill 模拟ES2015+
import "babel-polyfill";
// 引入阿里巴巴矢量库
import "@/assets/iconfont/iconfont.css"
import http from "@/api/http.js"
import { Toast } from 'vant';
import { Checkbox, CheckboxGroup, Popup } from 'vant';
import myConfirm from './components/MyConfirm/index.js'
Vue.prototype.$confirm = myConfirm
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Popup)
Vue.use(Toast)
Vue.prototype.$http = http;
import 'vant/lib/index.css';
//引入i18n国际化
import i18n from './i18n/index.js';
// import 'aframe-particle-system-component';
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
