const en = {
  login: 'login',
  digital: "Digital two-place",
  changePhoneText: "Please enter your mobile phone number",
  changeCodeText: "Please enter SMS verification code",
  registerOrLogin: 'Registration/Login',
  mailboxAccount: "Please enter your email account",
  mailboxLogin: 'Mailbox Logon',
  mailboxCode: 'Please enter your email verification code',
  readAndAgree: 'Read and agree',
  UserAgreement: '《User Agreement》',
  and: "and",
  privacyTerms: "《Privacy Terms》",
  phoneLogin: "Mobile number login",
  sRetrieve: "Re-acquisition after",
  Korean: "Korean",
  Japanese: "Japanese",
  myVideo: "My Digital avatar Video",
  loginOut: "Log out",
  getVerificationCode: "Get code",
  dimension: "Unregistered users will be automatically registered after verification",
  showText: "Please check the protocol first",
  shuaxin: "Refresh",
  deleteText: 'Successfully deleted',
  loginTitleText: "Please log in first",
  goToShoot: "Please find a staff member to take photos",
  inProductionText: 'in production',
  shootingRecord: "Records",
  AR: 'AR',
  templateVideo: "digital avatar Video",
  yes: "YES",
  no: "No",
  loginOutTitle: "Do you want to log out ？",
  modelListTitle: "Check Set as Default Display Model",
  logOffTitle: "Do you want to log off",
  deleteModelText: 'Delete the avatar？',
  isChangeModel: "Do you want to change the model display？",
  isLogOff: 'log off',
  delete: 'Delete',
  captureTime: "capture time",
  captureLocation: "capture location",
  loginOutSuccess: 'exit successfully',
  loginOutError: 'Exit failed',
  errNumber: "error number",
  versionErr: 'Verification code error',
  emailError: "Email error",
  emailFormat: 'E-mail format is incorrect',
  numNoNull: "Number cannot be empty",
  numberFormat: 'Number format error',
  sendSuccess: 'Sent successfully',
  sendError: 'Failed to send',
  missingParameters: 'Missing parameters',
  getVideoListError: 'Failed to get video',
  logOffSuccess: "Logout successful",
  logOffError: 'Logout failed',
  deleteSuccess: 'Successfully deleted',
  deleteError: 'failed to delete',
  getShootError: 'Failed to obtain shooting data',
  downLoad: 'Download',
  share: 'Share',
  shareText: "The sharing link has been copied and can be pasted and shared",
  downLoadSuccess: "The video starts downloading, please check it later in the folder/album",
  language: {
    zh: '中文',
    en: 'English',
    ko: '한국어',
    ja: '日本語',
  },
}
export default en;
