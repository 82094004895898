<template>
    <van-popup v-model="isShow">
        <div class="con_box">
            <h3>{{text.title}}</h3>
            <p>{{text.message}}</p>
            <div class="btn">
                <!-- <span @click="handleClose()" v-if="text.btn.cancelText">{{text.btn.cancelText}}</span> -->
                <span @click="handleOk()" v-if="text.btn.okText">{{text.btn.okText}}</span>
            </div>
        </div>
    </van-popup>
</template>
<script>
    export default {
        data() {
            return {
                isShow: true,
                text: {
                    title: '提示',
                    message: '确定删除此条信息？',
                    btn: {
                        okText: '确定',
                        // cancelText: '取消'
                    }
                }
            }
        },
        methods: {
            handleClose() {
                console.log('关闭')
            },
            handleOk() {
                console.log('确定')
            }
        }
    }
</script>
<style scoped>
    .con_box>h3 {
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .con_box>p {
        font-size: 17px;
        margin-bottom: 30px;
    }

    .btn>span {
        display: block;
        width: 220px;
        border-radius: 4px;
        background-color: #e0e5f5;
        text-align: center;
        line-height: 44px;
        font-size: 17px;
    }

    .btn>span:last-of-type {
        background-color: #1288fe;
        color: #ffffff;
    }

    .btn {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .van-popup {
        border-radius: 10px;
    }

    .con_box {
        width: 270px;
        line-height: 1;
        text-align: center;
        color: #4d5c82;
        padding: 15px;
        box-sizing: border-box;
    }
</style>